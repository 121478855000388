import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Modal, ModalHeader, Input, Row, Button, Table, Badge, Spinner, Collapse, Card, CardHeader, CardBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';

export const ProgramaTema = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [subTemas, setSubTemas] = useState([]);
    let [id,setId] = useState(0);
    let [idSub,setIdSub] = useState(0);
    let [texto,setTexto] = useState("");
    let [peso,setPeso] = useState(0);
    let [sesion,setSesion] = useState(0);
    let [sesiones, setSesiones] = useState([]);
    let [orden,setOrden] = useState(0);
    let [capturando,setCapturando] = useState(false)
    let [x,setX] = useState(0);
    let [collapse, setCollapse] = useState(false);

    const toggle = () => setCollapse(!collapse);

    useEffect(() => {
        (async () => {
            setSubTemas(props.tema.SubTemas); //cargarEjes();
            let sesiones = [];
            for(let i = 1; i <= props.tema.Sesiones; i++)
                sesiones.push(i);
            setSesiones(sesiones);
        })();
    }, [props.tema.Id]);

    async function cargarSubTemas() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/subtemas/?tema=${props.tema.Id}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setSubTemas(d);
    }
    function nuevoSubTema(){
        setId(0);
        setTexto("");
        setOrden(0);
        setPeso(0);
        setSesion(sesiones.length == 1 ? 1 : 0);
        setCapturando(true);
    }
    function editarSubTema(i){
        setId(subTemas[i].Id);
        setTexto(subTemas[i].Texto);
        setOrden(subTemas[i].Orden);
        setPeso(subTemas[i].Peso);
        setSesion(subTemas[i].Sesion);
        setCapturando(true);
    }
    function eliminarSubTema(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar el subtema "' + subTemas[i].Texto + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
            eliminarB(i);
        });
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarSubTema/${subTemas[i].Id}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") 
            cargarSubTemas();
        else
            Swal.fire('Atencion', res, 'error');     
    }
    async function guardarSubTema() {
        if(sesion == 0){
            Swal.fire('Atencion', 'debe especificar la sesion', 'error');     
            return;
        }
        const dato = {
            Id: id, Texto : texto, Orden:orden, Sesion : sesion, Eje: props.tema.Eje,
            Tema : props.tema.Id, Peso: peso, Usuario : edoGlobal.usuario.Id
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsguardarSubTema`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            setCapturando(false);
            cargarSubTemas();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    return (
        <div>
            <h6 className='rojo' onClick={toggle}>{props.eje}.{props.tema.Orden} - {props.tema.Texto}</h6>
            <Collapse isOpen={collapse}>
                <div style={{ padding: "0px 0px 0px 10px" }}>
                    <Table borderless className='table table-sm' style={{ width: '100%' }} >
                        <thead>
                            <tr style={{ borderBottom: '1px solid maroon',borderTop: '1px solid maroon' }}>
                                <th><h5><Badge style={{color:'white',backgroundColor:'black'}} >Subtemas</Badge> &nbsp;&nbsp;&nbsp;&nbsp;  <Button size="sm" color="secondary" outline onClick={() => nuevoSubTema()}><img src="/imagenes/add.png" alt="nuevo subtema" title="nuevo subtema" /><span className='negro'> Agregar subtema</span></Button></h5></th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {subTemas.map((o, i) =>
                                <tr key={i} >
                                    <td>
                                        <div><b className='rojo'>{props.eje}.{props.tema.Orden}.{o.Orden} - {o.Texto}</b></div>
                                    </td>
                                    <td><Button onClick={() => editarSubTema(i)} size="sm" color="secondary" outline title="editar" ><img src="imagenes/edit.png" title="editar" /></Button></td>
                                    <td><Button onClick={() => eliminarSubTema(i)} size="sm" color="secondary" outline title="eliminar" ><img src="imagenes/menos.png" title="eliminar" /></Button></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </Collapse>
            <Modal isOpen={capturando} toggle={() => setCapturando(false)}>
                <ModalHeader>
                    {id == 0 ? <span>Nuevo subtema</span> : <span>Editando subtema</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col'>
                            <span>Texto</span>
                            <Input type="textarea" value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                    </Row>
                    {
                        /*
                    <Row>
                        <div className='col-3'>
                            <span>Sesion</span>
                            <Input size="sm" type="select" value={sesion} onChange={(e) => setSesion(e.target.value)}>
                                <option value={0}>Especifique</option>
                                {sesiones.map((o,i)=> <option key={i} value={o}>{o}</option>)}
                            </Input>
                        </div>
                    </Row>

                        */
                    }
                    <br />
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={guardarSubTema}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
        </div>
    )
}