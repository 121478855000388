import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button, Table } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import {Materia} from './Materia';

export const Materias = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [materias,setMaterias] = useState([]);
    let [nombre,setNombre] = useState("");
    let [idMat,setIdMat] = useState(0);
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    
    useEffect(() => {
        (async () => {
            if(edoGlobal.urlws === 'http://localhost:6481/'){
                setNombre("emprende");
            }
            })();
    }, []);

    async function cargarMaterias(){
        if (nombre === "")
            return;
        setCargando(true);
        let url = `${edoGlobal.urlws}/materia/lista?inst=${edoGlobal.institucion}&filtro=${nombre}`;
        let resp = await fetch(url);
        const m = await resp.json();
        console.log(m);
        setCargando(false);
        setMaterias(m);
    }
    function nueva(){
        /*
        setIdPac(0);
        setEditando(true);
        */
    } 
    const editar = i =>{
        /*
        setIdPac(pacientes[i].I);
        setEditando(true);
        */
    }
    function programa(o){
        //setIdMat(o.Id);
        props.verDetalle(o);
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarMaterias();
    }
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return(
        <div>
            <div style={estilo2}></div>
            <Container style={estilo1}>
                <div className="row">
                    <div className="col-2">
                        <FormGroup  >
                            <span>Nombre</span>
                            <Input size="sm" type="text" value={nombre} onKeyPress={(e)=> {if(e.which == 13) cargarMaterias();}} onChange={e => setNombre(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <br />
                        <Button outline color="secondary" size="sm" onClick={cargarMaterias}><img src="imagenes/zoom.png" alt="buscar" /></Button>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th>Clave</th>
                            <th>Nombre</th>
                            <th className='celdaBoton'></th>
                        </tr>
                    </thead>
                        <tbody>
                            {materias.map((o,i)=>
                                <tr key={i} onClick={()=> programa(o)}>
                                    <td>{o.Clave}</td>
                                    <td>{o.Nombre}</td>
                                    <td>
                                        {
                                            o.Programa == 0 ?
                                            <img src="/imagenes/add.png" alt="nuevo" title="nuevo programa indicativo" />
                                            :
                                            <img src="/imagenes/edit.png" alt="editar" title="editar programa indicativo" />
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                </Table>
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' />
                </div>
                :
                null
            }
        </div>
    )
}
