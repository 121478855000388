import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Input, Row, Table, Button, Spinner, Modal, ModalHeader, ModalBody, CardText, Container } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';

export const GrupoAnotaciones = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [fechaCap,setFechaCap] = useState(new Date().yyyymmdd('-'));
    let [texto,setTexto] = useState("");
    let [alumno,setAlumno] = useState("");
    let [anotaciones,setAnotaciones] = useState([]);
    let [anotacion,setAnotacion] = useState({Id:0, Fecha:'', Texto:'', Alumno:''});
    let [capturando,setCapturando] = useState(false);
    let [cambios,setCambios] = useState(0);

    useEffect(() => {
        (async () => {
            cargarAnotaciones();
        })();
    }, []);

    function setCargando(v){
        props.setCargando(v);
    }
    async function cargarAnotaciones(){
        if(anotaciones.length !== 0)
           return;
        setCapturando(false);
        setCargando(true);
        let url = `${edoGlobal.urlws}/anotacion/anotaciones?grupo=${props.grupo.Id}&alumno=`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setAnotaciones(a);
    }
    function editar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        setAnotacion(t);
        setFechaCap(t.F);
        setTexto(t.Texto);
        setAlumno(t.Alumno);
        setCapturando(true);
    }
    function eliminar(t){
        if(!props.grupo.Abierto){
            Swal.fire('Atencion','el grupo esta cerrado','error');    
            return;
        } 
        Swal.fire('Atencion',"Opcion aun en desarrollo",'info');
    }
    async function guardar(){
        const dato = {
            Id : anotacion.Id,
            Grupo : props.grupo.Id,
            Fecha : fechaCap,
            Alumno : alumno,
            Texto :texto
        }
        let url = `${edoGlobal.urlws}/anotacion/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            anotaciones = [];
            cargarAnotaciones();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    return(
        <div>
            <Row>
                <div className='col'>
                    <table>
                        <tr style={{ verticalAlign: 'center' }}>
                            <td><span>Anotaciones</span></td>
                            <td><span>&nbsp;&nbsp;</span>
                               {props.grupo.Abierto && <img onClick={()=>{editar({Id:0,Fecha: fechaCap,Texto:'',Alumno:''})}} src="imagenes/add.png" alt="nuevo" title="nueva anotacion" />}
                            </td>
                        </tr>
                    </table>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th className='celdaFecha'>Dia</th>
                                <th>Nombre</th>
                                <th>Texto</th>
                                <th className='celdaBoton'></th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {anotaciones.map((o, i) => {
                                return (
                                    <tr key={i} >
                                        <td>{o.F}</td>
                                        <td>{o.N}</td>
                                        <td>{o.Texto}</td>
                                        <td><img onClick={()=> editar(o)} src="/imagenes/edit.png" alt="editar" title="editar" /></td>
                                        <td><img onClick={()=> eliminar(o)} src="/imagenes/menos.png" alt="eliminar" title="eliminar" /></td>
                                     </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={capturando} toggle={()=>setCapturando(false)}>
                <ModalHeader>
                    {anotacion.Id == 0 ? <span>Nueva anotacion</span> : <span>Editar anotacion</span>}
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col-4'>
                            <span>Fecha</span>
                            <Input size="sm" type="date" value={fechaCap} onChange={(e)=>setFechaCap(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <span>Tema</span>
                            <Input type="textarea" value={texto} onChange={(e)=>setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br/>
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={()=>guardar()}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br/>
            </Modal>
            </Row>
        </div>
    )
}