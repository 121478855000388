import React from 'react';
import { Input } from 'reactstrap';
import { Card, Button,CardBody } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';
export default class Login extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props);
        this.state = {
            inst:'UADO',
            usu:'',
            pwd:''
        };
    }
    componentDidMount() {
        const edoGlobal = this.context;
        if(edoGlobal.urlws === 'http://localhost:6481/'){
            this.state.usu = '';
            this.state.inst = 'UADO';
            this.state.pwd = '';
        }
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    loguear  = async() => {
        const edoGlobal = this.context;
        const usu = this.state.usu;
        const pwd = this.state.pwd;
        const inst = this.state.inst;
        const url = `${edoGlobal.urlws}/home/wsloguear?usu=${usu}&pwd=${pwd}&inst=${inst}`; // edoGlobal.institucion}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === "0"){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        edoGlobal.institucion = res.Institucion;
        edoGlobal.nombreEmpresa = res.NombreInst;
        this.props.iniciar(res);
    }
    render(){
        const edoGlobal = this.context;
        return(
            <Panel titulo="Inicio de sesión">
                <form >
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Usuario</span>
                                <input size="sm" className="form-control form-control-sm" type="text" name="usu" value={this.state.usu} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Contraseña</span>
                                <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <Button className="botonLince" color="secondary" size="sm" outline onClick={this.loguear}>Aceptar</Button>
                </form>
            </Panel>
        )
    }
}