import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Modal, ModalHeader, Input, Row, Button, Table, Badge, Spinner, Collapse, Card, CardHeader, CardBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';

export const Tecnicas = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [id,setId] = useState(0);
    let [capturando, setCapturando] = useState(false);
    let [tecnicas, setTecnicas] = useState([]);
    let [cargando, setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            cargarDatos();
            })();
    }, []);

    async function cargarDatos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/tecnicas`;
        let resp = await fetch(url);
        const m = await resp.json();
        setCargando(false);
        setTecnicas(m);
    }
    async function nuevo() {
        id = 0;
        setId(0);
        const { value: texto } = await Swal.fire({
            input: 'textarea',
            inputValue: '',
            inputLabel: 'Editando tecnica',
            inputPlaceholder: 'tecnica',
            confirmButtonText: 'aceptar',
            confirmButtonColor:'maroon'
        })
          
        if (texto) {
            guardar(texto);
        }
    }
    async function editar(i) {
        id = tecnicas[i].I;
        setId(tecnicas[i].I);
        const { value: texto } = await Swal.fire({
            input: 'textarea',
            inputValue: tecnicas[i].N,
            inputLabel: 'Editando tecnica',
            inputPlaceholder: 'tecnica',
            confirmButtonText: 'aceptar',
            confirmButtonColor:'maroon'
        })
          
        if (texto) {
            guardar(texto);
        }
    }
    async function guardar(texto) {
        const dato = {
            Id: id, Texto : texto, 
            Usuario : edoGlobal.usuario.Id 
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsGuardarTecnica`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") {
            cargarDatos();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    async function eliminar(o){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar "' + o.N + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                eliminarB(o.I);
        });
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarTecnica/${i}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") 
            cargarDatos();
        else
            Swal.fire('Atencion', res, 'error');     
    }
    return (
        <div>
                        {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' />
                </div>
                :
                null
            }

        <Table size="sm" hover striped>
            <thead>
                <th><h4><Badge style={{color:'white',backgroundColor:'maroon'}} >Tecnicas  <Badge size="sm" color="light">{tecnicas.length}</Badge> </Badge> <span>&nbsp;&nbsp;&nbsp;</span><Button style={{borderColor:'maroon'}} size="sm" color="secondary" outline onClick={nuevo}><img src="/imagenes/add.png" alt="nueva tecnica" title="nueva tecnica" /><span className='negro'> Agregar tecnica</span></Button></h4></th>
                <th className='celdaBoton'></th>
                <th className='celdaBoton'></th>
            </thead>
            <tbody>
                {tecnicas.map((o,i)=>
                <tr key={i}>
                    <td>{o.N}</td>
                    <td>
                        <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="editar" onClick={() => editar(i)}><img src="imagenes/edit.png" /></Button>
                    </td>
                    <td>
                        <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="eliminar" onClick={() => eliminar(o)} ><img src="imagenes/menos.png" /></Button>                        
                    </td>
                </tr>
                )}
            </tbody>
        </Table>
        </div>
    )
}