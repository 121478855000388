import React from 'react';
import { Table, Badge } from 'reactstrap';

export const ProgramaCompleto = (props) => {
    let sumaPond = 0;
    let sumaSesiones = 0;
    for (let i = 0; i < props.ejes.length; i++) {
        sumaPond += props.ejes[i].Ponderacion;
        sumaSesiones += props.ejes[i].Sesiones;
    }
    return (
        <Table size="sm" bordered striped hover>
            <tbody>
                {props.ejes.map((o, i) =>
                    <tr key={i} >
                        <td style={{ padding: 5, border: '1px solid maroon' }}>
                            <ProgramaCompletoEje key={i} eje={o} />
                        </td>
                    </tr>
                )}
                <tr>
                    <td style={{ padding: 5, border: '1px solid maroon' }}>
                        <div className='row'>
                            <div className='col-8'>
                            </div>
                            <div className='col-3'>
                                <h5>suma de la ponderacion</h5>
                            </div>
                            <div className='col-1'>
                                <Badge style={{ width: 40 }}><h5>&nbsp;{sumaPond}&nbsp;</h5></Badge>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}
const ProgramaCompletoEje = (props) =>{
    return (
        <div>
            <div className='row'>
                <div className='col-11'>
                    <h4><span className='rojo' >{props.eje.Orden}</span> - {props.eje.Texto}</h4>
                </div>
                <div className='col-1'>
                    <Badge style={{width:40}}><h6>&nbsp;{props.eje.Ponderacion}&nbsp;</h6></Badge>
                </div>
            </div>
                <div style={{padding:"0px 0px 0px 10px"}}>
                    <Table borderless className='table table-sm' style={{width:'100%'}} >
                        <thead>
                            <tr style={{borderBottom:'1px solid maroon'}}>
                                <th><b  >Temas</b></th>
                                <th className='celdaBoton'>Pond.</th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.eje.Temas.map((o,i) => 
                        <tr key={i} >
                            <td>
                                <ProgramaCompletoTema eje={props.eje.Orden} tema={o} />
                            </td>
                            <td><b>{o.Peso}</b></td>
                            <td></td>
                        </tr>
                        )}
                        </tbody>
                    </Table>
               </div>
                <div style={{padding:"0px 0px 0px 10px"}}>
                    <Table borderless className='table table-sm' style={{ width: '100%' }} >
                        <thead>
                            <tr style={{ borderBottom: '1px solid maroon' }}>
                                <th><b >Actividades</b></th>
                                <th><br/>Temas</th>
                                <th className='celdaBoton'>Pond.</th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.eje.Actividades.map((o, i) =>
                                <tr key={i} >
                                    <td>
                                        <b className='rojo'>{o.Orden} - {o.Titulo}</b><br/>
                                        <b>{o.Texto}</b>
                                    </td>
                                    <td className='celdaTema'><b className='rojo'>{o.Temas}</b></td>
                                    <td><b>{o.Puntos}</b></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
               </div>
       </div>
    )
}
const ProgramaCompletoTema = (props) =>{
    return (
        <div>
            <h6 className='rojo' >{props.eje}.{props.tema.Orden} - {props.tema.Texto}</h6>
            <div>
                <div style={{ padding: "0px 0px 0px 10px" }}>
                    <Table borderless className='table table-sm' style={{ width: '100%' }} >
                        <tbody>
                            {props.tema.SubTemas.map((o, i) =>
                                <tr key={i} >
                                    <td>
                                        <div><b className='rojo'>{props.eje}.{props.tema.Orden}.{o.Orden} - {o.Texto}</b></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}