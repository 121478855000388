import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Modal, Input,InputGroup, FormGroup, Button, Table, Row, Card, CardBody, CardTitle, CardSubtitle, CardText, ModalBody, ModalHeader, Badge } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'
import {Tabla} from './Tabla';

export const GruposAlumno = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [carreras,setCarreras] = useState([]);
    let [grupos,setGrupos] = useState([]);
    let [periodos,setPeriodos] = useState([]);
    let [periodo,setPeriodo] = useState(0);
    let [materias,setMaterias] = useState([]);
    let [idGrupo,setIdGrupo] = useState(0);
    let [idMateria,setIdMateria] = useState(0);
    let [carrera,setCarrera] = useState(0);
    let [filtro,setFiltro] = useState("");
    let [nombre,setNombre] = useState("");
    let [aula,setAula] = useState("");
    let [grupo,setGrupo] = useState("");
    let [turno,setTurno] = useState("");
    let [lunes,setLunes] = useState("");
    let [martes,setMartes] = useState("");
    let [miercoles,setMiercoles] = useState("");
    let [jueves,setJueves] = useState("");
    let [viernes,setViernes] = useState("");
    let [abierto,setAbierto] = useState(false);
    let [verModal,setVerModal] = useState(false);
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    
    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/periodo/todos/${edoGlobal.institucion}`;
            let resp = await fetch(url);
            const periodos = await resp.json();
            url = `${edoGlobal.urlws}/grupo/carreras/${edoGlobal.institucion}`;
            resp = await fetch(url);
            const carreras = await resp.json();
            setPeriodos(periodos);
            setCarreras(carreras);
            setCargando(false);
        })();
    }, []);

    async function cargarGrupos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/alumno/grupos/${edoGlobal.usuario.Id}?inst=${edoGlobal.institucion}&periodo=${periodo}`;
        let resp = await fetch(url);
        const grupos = await resp.json();
        let gpos = [];
        let col = 1;
        let i = 0;
        for (i = 0; i < grupos.length; i++){
            if(col == 1){
               col = 2;
            }
            else{
                gpos.push({a:grupos[i-1], b:grupos[i]});
                col = 1;
            }
        }
        if(col == 2 && grupos.length > 0)
           gpos.push({a:grupos[grupos.length-1], b:null});
        setCargando(false);
        setGrupos(gpos); //grupos);
    }
    async function cargarFiltro(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/materia/lista?inst=${edoGlobal.institucion}&filtro=${filtro}`;
        let resp = await fetch(url);
        const m = await resp.json();
        setCargando(false);
        setMaterias(m);
    }
    function nuevoGrupo(){
        setIdGrupo(0);
        setEditando(true);
    } 
    async function editar(i){
        setIdGrupo(grupos[i].Id);
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/cargar/${grupos[i].Id}`;
        let resp = await fetch(url);
        const o = await resp.json();
        setCargando(false);
        setCarrera(o.Carrera);
        setIdMateria(o.Materia);
        setNombre(o.NomMateria);
        setAula(o.Aula);
        setGrupo(o.Gpo);
        setTurno(o.Turno);
        setLunes(o.Lunes);
        setMartes(o.Martes);
        setMiercoles(o.Miercoles);
        setJueves(o.Jueves);
        setViernes(o.Viernes);
        setEditando(true);
    } 
    function abrir(i,col){
        let gpo = grupos[i].a;
        if(col == 2)
           gpo = grupos[i].b;
        props.grupoSeleccionado(gpo); //grupos[i]);
    }
    function selecionaMateria(i){
        setVerModal(false);
        setNombre(materias[i].Nombre);
        setIdMateria(materias[i].Id)
    }

    async function guardar(){
        let inst = edoGlobal.institucion;
        const dato = {
            Id : idGrupo, Institucion : edoGlobal.institucion,
            Periodo:periodo, Profesor : edoGlobal.usuario.Id,
            Materia : idMateria, Aula :aula, Gpo : grupo, Turno : turno, Carrera : carrera,
            Lunes : lunes, Martes : martes, Miercoles : miercoles, Jueves : jueves, Viernes : viernes,
            FechaIni:0, FechaFin:0, Clave : '', Examen1:0, Examen2 : 0, Examen3:0
        }
        let url = `${edoGlobal.urlws}/grupo/wsguardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            setIdGrupo(0);
            setEditando(false);
            cargarGrupos();    
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    /*
    function nuevoPaciente(){
        setIdPac(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdPac(pacientes[i].I);
        setEditando(true);
    }
    const consultar = i =>{
        setIdPac(pacientes[i].I);
        setConsultando(true);
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarPacientes();
    }
    */
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return(
        <div>
            <Container style={estilo1}>
                <div className="row">
                    <table>
                        <tr>
                            <td>Periodo&nbsp;</td>
                            <td>
                                <Input type="select" size="sm" value={periodo} onChange={(e) => setPeriodo(e.target.value)} >
                                    <option value={0}>Todos</option>
                                    {periodos.map((o, i) => (
                                        <option key={i} value={o.Id}>
                                            {o.Nombre}
                                        </option>
                                    ))}
                                </Input>
                            </td>
                            <td>&nbsp;&nbsp;<Button outline color="secondary" size="sm" onClick={cargarGrupos}><img src="imagenes/zoom.png" alt="buscar" /></Button></td>
                            <td>&nbsp;
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='row'>
                    <div className='col-1'>
                    </div>
                    <div className='col'>
                        <Table size="sm" borderless>
                            <tbody>
                                {grupos.map((o, i) => {
                                    return (
                                        <tr key={i} style={{ padding: 15 }}>
                                            <td>
                                                <Card className="cardGrupo">
                                                    <CardBody>
                                                        <CardTitle tag="h5">
                                                            <a href="#" onClick={() => abrir(i, 1)}><Badge style={{ backgroundColor: 'white', color: 'maroon' }}>{o.a.Materia}</Badge></a>
                                                        </CardTitle>
                                                        <div>
                                                            <Row>
                                                                <div className='col'>
                                                                    <span>Profesor : </span><b>{o.a.Profesor}</b>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className='col-4'>
                                                                    <span>Semestra : </span><b>{o.a.Periodo}</b>
                                                                </div>
                                                                <div className='col'>
                                                                    <span>Horario : </span><b>{o.a.Horario}</b>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className='col-1'>
                                                                    <Button size="sm" color="secondary" outline onClick={() => abrir(i, 1)}>Abrir</Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </td>
                                            <td><span>&nbsp;</span></td>
                                            {o.b != null && <td>
                                                <Card className="cardGrupo">
                                                    <CardBody>
                                                        <CardTitle tag="h5">
                                                            <a href="#" onClick={() => abrir(i, 2)}><Badge style={{ backgroundColor: 'white', color: 'maroon' }}>{o.b.Materia}</Badge></a>
                                                        </CardTitle>
                                                        <div>
                                                            <Row>
                                                                <div className='col'>
                                                                    <span>Profesor : </span><b>{o.b.Profesor}</b>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className='col-4'>
                                                                    <span>Semestra : </span><b>{o.b.Periodo}</b>
                                                                </div>
                                                                <div className='col'>
                                                                    <span>Horario : </span><b>{o.b.Horario}</b>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className='col-1'>
                                                                    <Button size="sm" color="secondary" outline onClick={() => abrir(i, 2)}>Abrir</Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </td>}
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className='col-1'>
                    </div>
                </div>
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' />
                </div>
                :
                null
            }
        </div>
    )
}