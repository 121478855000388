import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Modal, ModalHeader, Input, Row, Button, Table, Badge, Spinner, Card, CardHeader, CardBody, InputGroup, Tooltip, Collapse} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { ProgramaEje } from './ProgramaEje';
import { ProgramaCompleto } from './ProgramaCompleto';

export const Programa = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [programa, setPrograma] = useState({});
    let [idPrograma, setIdPrograma] = useState({});
    let [ejes, setEjes] = useState([]);
    let [eje, setEje] = useState({});
    let [tiposAct, setTiposAct] = useState([]);
    let [parciales, setParciales] = useState([]);
    let [sumaPond, setSumaPond] = useState(0);
    let [sumaSesiones, setSumaSesiones] = useState(0);
    let [cambios, setCambios] = useState(0);
    let [ptjeEx, setPtjeEx] = useState(0);
    let [ptjeAsis, setPtjeAsis] = useState(0);
    let [ptjeTar, setPtjeTar] = useState(0);
    let [ptjePart, setPtjePart] = useState(0);
    let [opcion, setOpcion] = useState(1);
    let [cargando, setCargando] = useState(false);
    let [capturando, setCapturando] = useState(false);
    let [pondera, setPondera] = useState(0);
    let [texto, setTexto] = useState("");
    let [aprendizaje, setAprendizaje] = useState("");
    let [estrategia, setEstrategia] = useState("");
    let [tecnica, setTecnica] = useState("");
    let [vistaEjes,setVistaEjes] = useState(1);
    let [collapse, setCollapse] = useState(false);

    let [x, setX] = useState(0);
    let [tecnicasProd,setTecnicasProd] = useState([]);
    let [estrategiasProd,setEstrategiasProd] = useState([]);

    const toggle = () => setCollapse(!collapse);

    useEffect(() => {
        (async () => {
            cargar(props.id);
        })();
    }, [props.id]);

    async function cargar(id) {
        setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/cargar/${id}`;
        let resp = await fetch(url);
        const p = await resp.json();
        setIdPrograma(id);
        setPrograma(p);
        setPtjeEx(p.PtjeExamen);
        setPtjeAsis(p.PtjeAsistencias);
        setPtjeTar(p.PtjeTareas);
        setPtjePart(p.PtjeParticipaciones);

        url = `${edoGlobal.urlws}/programaIndicativo/tecnicasSelProdTerminal/${id}`;
        resp = await fetch(url);
        let d = await resp.json();
        setTecnicasProd(d);
        url = `${edoGlobal.urlws}/programaIndicativo/estrategiasSelProdTerminal/${id}`;
        resp = await fetch(url);
        d = await resp.json();
        setEstrategiasProd(d);

        url = `${edoGlobal.urlws}/programaIndicativo/ejes/?pi=${id}`;
        resp = await fetch(url);
        d = await resp.json();
        sumaPond = 0;
        sumaSesiones = 0;
        for(let i = 0; i < d.length; i++){
            sumaPond += d[i].Ponderacion;
            sumaSesiones += d[i].Sesiones;
        }
        url = `${edoGlobal.urlws}/programaIndicativo/parciales/?inst=${edoGlobal.institucion}`;
        resp = await fetch(url);
        const par = await resp.json();
        setParciales(par);

        url = `${edoGlobal.urlws}/programaIndicativo/tiposActividades`;
        resp = await fetch(url);
        const tpa = await resp.json();
        setTiposAct(tpa);

        setCargando(false);
        setEjes(d);
        setSumaPond(sumaPond);
        setSumaSesiones(sumaSesiones);
        setOpcion(3);
    }
    async function cargarEjes() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/ejes/?pi=${idPrograma}`;
        let resp = await fetch(url);
        const d = await resp.json();
        setCargando(false);
        setCapturando(false);
        sumaPond = 0;
        sumaSesiones = 0;
        for(let i = 0; i < d.length; i++){
            sumaPond += d[i].Ponderacion;
            sumaSesiones += d[i].Sesiones;
        }
        setEjes(d);
        setSumaPond(sumaPond);
        setSumaSesiones(sumaSesiones);
    }
    async function cargarEje(id) {
        setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/cargarEje/${id}`;
        let resp = await fetch(url);
        const u = await resp.json();
        if(id == 0)
            u.Programa = idPrograma;
        setCargando(false);
        setEje(u);
        setTexto(u.Texto);
        setPondera(u.Ponderacion);
        setEstrategia(u.Estrategias);
        setAprendizaje(u.AprendizaEsperado);
        setTecnica(u.TecnicaEvaluacion);
        setCapturando(true);
    }
    async function cambiarCampo(titulo,campo,texto) {
        const dato = {
            Id: programa.Id, Dato : campo, Valor:texto
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsCambiarDato`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") {
            url = `${edoGlobal.urlws}/programaIndicativo/cargar/${idPrograma}`;
            resp = await fetch(url);
            const p = await resp.json();
            setPrograma(p);
        }
        else
            Swal.fire('Atencion', res, 'error');
        return res;
    }
    async function editar(i) {
        eje =ejes[i];
        setEje(ejes[i]);
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputValue: ejes[i].Texto,
            inputLabel: 'Editando eje tematico',
            inputPlaceholder: 'nombre del eje tematico',
            confirmButtonText: 'aceptar',
            confirmButtonColor:'silver'
        })
          
        if (text) {
            guardarEje(eje,text);
        }
    }
    function eliminar(i){
        if(ejes[i].Temas.length > 0){
            Swal.fire('Atencion', 'No se puede eliminar el eje porque tiene temas registrados', 'error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar "' + ejes[i].Texto + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                eliminarB(ejes[i].Id);
        });
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarEje/${i}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") 
            cargarEjes();
        else
            Swal.fire('Atencion', res, 'error');     
    }
    
    async function guardar() {
        let suma = Number(ptjeEx) + Number(ptjeAsis) + Number(ptjeTar)+ Number(ptjePart);
        if(suma != 100){
            //Swal.fire('Atencion', 'la suma de la ponderacion debe ser igual a 100', 'error');
            //return;
        }
        const dato = {
            Id: idPrograma,
            PtjeExamen : ptjeEx, PtjeAsistencias:ptjeAsis,
            PtjeTareas : ptjeTar, PtjeParticipaciones:ptjePart,
            Usuario : edoGlobal.usuario.Id 
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsguardar`;
        setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        let res = await resp.text();
        setCargando(false);
        if (res.substring(0, 2) !== "Er") {
            url = `${edoGlobal.urlws}/programaIndicativo/cargar/${idPrograma}`;
            resp = await fetch(url);
            const p = await resp.json();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    async function guardarEje() {
        if(idPrograma == 0){
            const dato = {
                Id: idPrograma, Materia: props.materia.Id, Version:props.version,
                PtjeExamen : ptjeEx, PtjeAsistencias:ptjeAsis,
                PtjeTareas : ptjeTar, PtjeParticipaciones:ptjePart,
                Usuario : edoGlobal.usuario.Id 
            }
            setCargando(true);
            let urlG = `${edoGlobal.urlws}/programaIndicativo/wsguardar`;
            let jsonG = JSON.stringify(dato);
            let respG = await fetch(urlG, {
                method: 'POST', // or 'PUT'
                body: jsonG // data can be `string` or {object}!
            });    
            let resG = await respG.text();
            setCargando(false);
            if (resG.substring(0, 2) !== "Er") {
                idPrograma = Number(resG);
                eje.Programa = idPrograma;
                setIdPrograma(idPrograma);
            }
            else{
                Swal.fire('Atencion', res, 'error');
                return;
            }
        }
        const dato = {
            Id: eje.Id, Texto : texto, Programa : eje.Programa,
            AprendizaEsperado : aprendizaje, Estrategias : estrategia,
            TecnicaEvaluacion : tecnica, Ponderacion:  pondera,
            orden: eje.Orden, Usuario : edoGlobal.usuario.Id 
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsGuardarEje`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") {
            cargarEjes();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    function actualizarPonderacion(i,p){
        ejes[i].Ponderacion = p;
        sumaPond = 0;
        for(let i = 0; i < ejes.length; i++)
            sumaPond += ejes[i].Ponderacion;
        setSumaPond(sumaPond);
    } 
    function actualizarSesiones(i,p){
        ejes[i].Sesiones = p;
        sumaSesiones = 0;
        for(let i = 0; i < ejes.length; i++)
            sumaSesiones += ejes[i].Sesiones;
        setSumaSesiones(sumaSesiones);
    } 
    async function mostrarSesiones(){
        await cargarEjes();
        setVistaEjes(2)
    }
    async function tecnicaSel(i,c){
        let sel = c ? 'S':'N';
        let tec = tecnicasProd[i].I;
        //alert("pi:" + idPrograma + "// tecnica: " + tec + " // sel :" + sel)
        let url = `${edoGlobal.urlws}/programaIndicativo/guardarTecnicaProdTerminal/${idPrograma}?tecnica=${tec}&sel=${sel}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res !== 'ok')
            return;
        tecnicasProd[i].S = c;
        setX(x+1);
    }
    async function estrategiaSel(i,c){
        let sel = c ? 'S':'N';
        let est = estrategiasProd[i].I;
        let url = `${edoGlobal.urlws}/programaIndicativo/guardarEstrategiaProdTerminal/${idPrograma}?estrategia=${est}&sel=${sel}`;
        setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if(res !== 'ok')
            return;
        estrategiasProd[i].S = c;
        setX(x+1);
    }

    const estiloObjetivo = { display: opcion !== 1 ? 'none' : 'inline' };
    const estiloCompetencias = { display: opcion !== 2 ? 'none' : 'inline' };
    const estiloEjes = { display: opcion !== 3 ? 'none' : 'inline' };
    const estiloEjesA = { display: vistaEjes !== 1 ? 'none' : 'inline' };
    const estiloEjesB = { display: vistaEjes == 1 ? 'none' : 'inline' };
    const estiloReferencias = { display: opcion !== 5 ? 'none' : 'inline' };
    const estiloExamenes = { display: opcion !== 4 ? 'none' : 'inline' };
    return(
        <div>            
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' />
                </div>
                :
                null
            }            
            <h6>{props.nombre}</h6>
            <div>
                <Row>
                    <div className='col-1'>
                        <Table size="sm" hover>
                            <tr>
                                <td>
                                    <Button onClick={() => setOpcion(1)} size="sm" color="warning" outline title="temas">
                                        <img src="/imagenes/reporte.png" alt="temas" />
                                        <br />
                                    </Button>
                                    <br/>Objetivo
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button onClick={() => setOpcion(2)} size="sm" color="warning" outline title="temas">
                                        <img src="/imagenes/temas.png" alt="temas" />
                                        <br />
                                    </Button>
                                    <br/>Competencias
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button onClick={() => setOpcion(3)} size="sm" color="warning" outline title="unidades">
                                        <img src="/imagenes/unidades.png" alt="unidades" />
                                        <br />
                                    </Button>
                                    <br/>Ejes
                                </td>
                            </tr>
                            {ejes.length != 0 && ejes[0].Temas.length != 0 &&
                            <tr>
                                <td>
                                    <Button onClick={() => setOpcion(4)} size="sm" color="warning" outline title="examenes">
                                        <img src="/imagenes/examen.png" alt="examenes" />
                                        <br />
                                    </Button>
                                    <br/>Examenes
                                </td>
                            </tr>
                            }
                            <tr>
                                <td>
                                    <Button onClick={() => setOpcion(5)} size="sm" color="warning" outline title="referencias">
                                        <img src="/imagenes/libro.png" alt="referencias" />
                                        <br />
                                    </Button>
                                    <br/>Referencias
                                </td>
                            </tr>
                        </Table>
                        <br />
                    </div>
                    <div className='col-11'>
                        <div className='col'>
                            <div style={estiloObjetivo}>
                                <Seccion titulo="Proposito" texto={programa.Proposito} campo="proposito" editar={cambiarCampo} />
                                <br />
                                <Seccion titulo="Objetivo de aprendizaje" texto={programa.Objetivo} campo="objetivo" editar={cambiarCampo} />
                                <br />
                                <Row>
                                    <div className='col-6'>
                                        <Card className="cardGrupo" outline style={{ border: '1px solid maroon', padding: '2px' }}>
                                            <CardHeader>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <h6 className='rojo'>Ponderacion</h6>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        <span>Examen</span>
                                                        <Input size="sm" value={ptjeEx}  />
                                                    </div>
                                                    <div className='col-2'>
                                                        <span>Asistencia</span>
                                                        <Input size="sm" value={ptjeAsis} onChange={(e) => setPtjeAsis(e.target.value)} />
                                                    </div>
                                                    <div className='col-2'>
                                                        <span>Actividades</span>
                                                        <Input size="sm" value={ptjeTar} />
                                                    </div>
                                                    <div className='col-3'>
                                                        <span>Producto terminal</span>
                                                        <Input size="sm" value={ptjePart} onChange={(e) => setPtjePart(e.target.value)} />
                                                    </div>
                                                    <div className='col-3'>
                                                        <span>Suma</span>
                                                        <Input size="sm" value={Number(ptjeEx) + Number(ptjeAsis) + Number(ptjeTar)+ Number(ptjePart)} onChange={(e) => setPtjePart(e.target.value)} />
                                                    </div>
                                                    <div className='col-2'>
                                                        <br />
                                                        <Button size="sm" className="botonLince" color="secondary" outline onClick={guardar}>Guardar</Button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloCompetencias}>
                                <h5 className='rojo'>Competencias genericas a desarrollar</h5>
                                <Seccion titulo="Instrumentales" texto={programa.CompetenciasInstrumentales} campo="competenciasInstrumentales" editar={cambiarCampo} />
                                <br/>
                                <Seccion titulo="Intrapersonales" texto={programa.CompetenciasIntrapersonales} campo="competenciasIntrapersonales"  editar={cambiarCampo}/>
                                <br/>
                                <Seccion titulo="Sistemicas" texto={programa.CompetenciasSistemicas} campo="competenciasSistemicas"  editar={cambiarCampo}/>
                                <h5 className='rojo'>Competencias especificas</h5>
                                <Seccion titulo="Competencias especificas" texto={programa.CompetenciasEspecificas} campo="competenciasEspecificas"  editar={cambiarCampo}/>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloEjes}>
                                <div style={estiloEjesA}>
                                    <h4>
                                        <Badge style={{ color: 'white', backgroundColor: 'maroon' }} >Ejes tematicos  <Badge size="sm" color="light">{ejes.length}</Badge> </Badge> 
                                        <span>&nbsp;&nbsp;&nbsp;</span><Button className="botonLince" size="sm" color="secondary" outline onClick={() => cargarEje(0)}><img src="/imagenes/add.png" alt="nuevo eje" title="nuevo eje" /><span className='negro'> Agregar eje</span></Button>
                                        <span>&nbsp;&nbsp;&nbsp;</span><Button size="sm" color="secondary" outline onClick={mostrarSesiones}><span className='negro'> ver sesiones</span></Button>
                                    </h4>
                                    <Table size="sm" striped hover>
                                        <tbody>
                                            {ejes.map((o, i) =>
                                                <tr key={i} >
                                                    <td >
                                                            <Card style={{padding:4}} className='cardEje'>
                                                                <ProgramaEje key={i} indice={i} eje={o} editar={() => cargarEje(o.Id)} eliminar={() => eliminar(i)} setCargando={setCargando} actualizarPonderacion={actualizarPonderacion} actualizarSesiones={actualizarSesiones} parciales={parciales} tiposAct={tiposAct} />
                                                            </Card>
                                                    </td>
                                                </tr>
                                            )}
                                            <Card style={{ padding: 4 }} className='cardEje'>
                                                <div className='row'>
                                                    <div className='col-10'><h4 onClick={toggle}>Producto terminal</h4></div>
                                                    <div className='col-1'>
                                                        <Badge style={{ width: 40 }}><h5>&nbsp;{programa.ProductoTerminalPonderacion}&nbsp;</h5></Badge>
                                                    </div>
                                                </div>
                                                <Collapse isOpen={collapse}>
                                                    <Seccion titulo="Aprendizaje esperado" texto={programa.ProductoTerminal} campo="productoTerminal" editar={cambiarCampo} />
                                                    <br/>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <Card className="cardGrupo" style={{ padding: 4 }}>
                                                                <b className='rojo'>Estrategias de aprendizaje</b>
                                                                <div className='lista-responsive'>
                                                                    {estrategiasProd.map((o, i) =>
                                                                        <div>
                                                                            <input type="checkbox" checked={o.S} onChange={(e) => estrategiaSel(i, e.target.checked)} />
                                                                            <span> - {o.N}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                        <div className='col'>
                                                            <Card className="cardGrupo" style={{ padding: 4 }}>
                                                                <b className='rojo'>Técnicas de evaluación</b>
                                                                <div className='lista-responsive'>
                                                                    {tecnicasProd.map((o, i) =>
                                                                        <div>
                                                                            <input type="checkbox" checked={o.S} onChange={(e) => tecnicaSel(i, e.target.checked)} />
                                                                            <span> - {o.N}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                        <div className='col-2'>
                                                            <Seccion titulo="Ponderacion" texto={programa.ProductoTerminalPonderacion} campo="ProductoTerminalPonderacion" editar={cambiarCampo} />
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </Card>
                                            <tr>
                                                <td style={{ padding: 5, border: '1px solid maroon' }}>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h5>suma de la ponderacion</h5>
                                                        </div>
                                                        <div className='col-1'>
                                                            <Badge style={{ width: 40 }}><h5>&nbsp;{sumaPond + programa.ProductoTerminalPonderacion}&nbsp;</h5></Badge>
                                                        </div>
                                                        <div className='col-1'>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={estiloEjesB}>
                                    <h4><span>&nbsp;&nbsp;&nbsp;</span><Button size="sm" color="secondary" outline onClick={() => setVistaEjes(1)}><span className='negro'> regresar a ejes</span></Button></h4>
                                    {vistaEjes == 2 && <ProgramaSesiones pi={idPrograma} ejes={ejes} programa={programa} />}
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloExamenes}>
                                <Examenes pi={programa.Id} ejes={ejes} setCargando={setCargando}/>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloReferencias}>
                                <Referencias pi={programa.Id} setCargando={setCargando}/>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <Modal size="lg" isOpen={capturando} toggle={() => setCapturando(false)}>
                <ModalHeader>
                    <h6 className='rojo'>{eje.Id == 0 ? "nuevo eje":"editando eje"}</h6>
                </ModalHeader>
                <Container>
                    <br />
                    <Row>
                        <div className='col'>
                            <span>Nombre</span>
                            <Input type="textarea" style={{ height: 100 }} value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <span>Aprendizaje esperado</span>
                            <Input type="textarea" style={{ height: 100 }} value={aprendizaje} onChange={(e) => setAprendizaje(e.target.value)} />
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className='col-2'>
                        </div>
                        <div className='col-2'>
                        </div>
                        <div className='col'>
                            <br/>
                            <Button size="sm" className="botonLince" disabled={texto === ""} color="secondary" outline onClick={guardarEje}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
        </div>
    )
}

const Seccion = (props) => {
    let [capturando, setCapturando] = useState(false);
    let [texto, setTexto] = useState("");
    function editar(){
        setTexto(props.texto);
        setCapturando(true);
        //props.editar(props.titulo, props.campo, props.texto)
    }
    async function guardar(){
        let res = await props.editar(props.titulo, props.campo, texto)
        if(res == "ok")
            setCapturando(false);
    }
    return (
        <div>
            <Card className="cardGrupo" outline style={{ border: '1px solid maroon', padding: '2px' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col-11'>
                            <h6 className='rojo'>{props.titulo}</h6>
                        </div>
                        <div className='col'>
                            <Button className="botonLince" size="sm" color="secondary" outline onClick={editar}><img src="/imagenes/edit.png" alt="editar" title="editar" /><span className='negro'></span></Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <span>
                        {props.texto}
                    </span>
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={capturando} toggle={() => setCapturando(false)}>
                <ModalHeader>
                    <h6 className='rojo'>{props.titulo}</h6>
                </ModalHeader>
                <Container>
                    <br/>
                    <Row>
                        <div className='col'>
                            <Input type="textarea" style={{height:300}} value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" className="botonLince" outline onClick={guardar}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
        </div>
    )
}

const Examenes = (props) => {
    const edoGlobal = useContext(AppContext);
    let [examenes, setExamenes] = useState([]);
 
    useEffect(() => {
        (async () => {
            cargarExamenes();
        })();
    }, [props.pi]);

    async function cargarExamenes() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/examenes?pi=${props.pi}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setExamenes(d);
    }
    function cargar(o){

    }
    function eliminar(o){
    }
    async function nuevo(){
        let parcial = examenes.length + 1;
        let texto = "EXAMEN DE EVALUACIÓN - PARCIAL " + parcial;
        const dato = {
            Id: 0, Texto : texto, Orden:0, Usuario : edoGlobal.usuario.Id, Parcial : parcial,
            Eje: props.ejes[0].Id, Tema:props.ejes[0].Temas[0].Id, SubTema : 0, Puntos: 0, Individual : true,
            Paginas:"", ExtraClase: false, Titulo:texto, Tipo : "E",
            Temas:"", Estrategias:"", Tecnicas:""
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsguardarActividad`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") {
            cargarExamenes();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    return (
        <div>
            <h4><Badge style={{ color: 'white', backgroundColor: 'maroon' }} >Examenes  <Badge size="sm" color="light">{examenes.length}</Badge> </Badge> <span>&nbsp;&nbsp;&nbsp;</span>
                {examenes.length < 3 && <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline onClick={nuevo}><img src="/imagenes/add.png" alt="nuevo" title="nuevo examen" /><span className='negro'> Agregar examen</span></Button>}
            </h4>
            <div style={{ border: '1px solid maroon', padding: '2px' }}>
                <Table size="sm" hover striped >
                    <thead>
                        <tr>
                            <th >Titulo</th>
                            <th className='celdaBoton' ></th>
                            <th className='celdaBoton'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {examenes.map((o, i) =>
                            <tr key={i} >
                                <td>{o.Titulo}</td>
                                <td>
                                    <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="editar" onClick={() => cargar(o)}><img src="imagenes/edit.png" /></Button>
                                </td>
                                <td>
                                    <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="eliminar" onClick={() => eliminar(o)} ><img src="imagenes/menos.png" /></Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
const Referencias = (props) => {
    const edoGlobal = useContext(AppContext);
    let [tipos, setTipos] = useState([]);
    let [referencias, setReferencias] = useState([]);
    let [id,setId] = useState(0);
    let [titulo,setTitulo] = useState("");
    let [autor,setAutor] = useState("");
    let [edicion,setEdicion] = useState("");
    let [editorial,setEditorial] = useState("");
    let [tipo,setTipo] = useState(0);
    let [orden,setOrden] = useState(0);
    let [capturando,setCapturando] = useState(false)
 
    useEffect(() => {
        (async () => {
            cargarTipos(); 
            cargarReferencias();
        })();
    }, [props.pi]);

    async function cargarTipos() {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/tiposReferencia`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setTipos(d);
    }
    async function cargarReferencias() {
        setCapturando(false);
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/referencias?pi=${props.pi}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        setReferencias(d);
    }
    async function cargarReferencia(i) {
        props.setCargando(true);
        let url = `${edoGlobal.urlws}/programaIndicativo/cargarReferencia?id=${i}`;
        let resp = await fetch(url);
        const d = await resp.json();
        props.setCargando(false);
        id = i;
        setId(i);
        setTitulo(d.Titulo);
        setAutor(d.Autor);
        setEdicion(d.Edicion);
        setEditorial(d.Editorial);
        setTipo(d.Tipo);
        setOrden(d.Orden);
        setCapturando(true);
    }
    async function agregarTipo(){
        const { value: text } = await Swal.fire({
            input: 'text',
            inputValue: '',
            inputLabel: 'Tipo de referencia',
            inputPlaceholder: 'tipo de referencia',
            confirmButtonText: 'aceptar',
            confirmButtonColor:'silver'
        })          
        if (text) {
            let nombre = text.toUpperCase();
            for(let i = 0; i < tipos.length; i++)
                if(tipos[i] == nombre)
            props.setCargando(true);
            let url = `${edoGlobal.urlws}/programaIndicativo/agregarTipoReferencia?nombre=${nombre}`;
            let resp = await fetch(url);
            let res = await resp.text();
            props.setCargando(false);
            if(res == "ok")
                cargarTipos();
            else
               Swal.fire('Atencion', res, 'error');
        }
    }
    async function guardar(){
        if(tipo == 0){
            Swal.fire('Atencion','especifique el tipo', 'error');
            return;
        }
        const dato = {
            Id:id, Programa: props.pi, Orden:orden,
            Tipo:tipo, Titulo : titulo, Autor : autor,
            Edicion : edicion, Editorial : editorial
        }
        let url = `${edoGlobal.urlws}/programaIndicativo/wsGuardarReferencia`;
        props.setCargando(true);
        let json = JSON.stringify(dato);
        let resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") 
            cargarReferencias();
        else
            Swal.fire('Atencion', res, 'error');
        return res;
    }
    async function eliminar(o){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: '¿ Desea eliminar "' + o.N + '" ?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                eliminarB(o.I);
        });
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/programaIndicativo/eliminarReferencia/${i}`;
        props.setCargando(true);
        const resp = await fetch(url);
        const res = await resp.text();
        props.setCargando(false);
        if (res === "ok") 
            cargarReferencias();
        else
            Swal.fire('Atencion', res, 'error');     
    }
    return (
        <div>
            <h4><Badge style={{ color: 'white', backgroundColor: 'maroon' }} >Referencias  <Badge size="sm" color="light">{referencias.length}</Badge> </Badge> <span>&nbsp;&nbsp;&nbsp;</span><Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline onClick={() => cargarReferencia(0)}><img src="/imagenes/add.png" alt="nueva" title="nueva referencia" /><span className='negro'> Agregar referencia</span></Button></h4>
            <div style={{ border: '1px solid maroon', padding: '2px' }}>
                <Table size="sm" hover striped >
                    <thead>
                        <tr>
                            <th className='celdaMed'>Tipo</th>
                            <th >Titulo</th>
                            <th className='celdaBoton' ></th>
                            <th className='celdaBoton'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {referencias.map((o, i) =>
                            <tr key={i} >
                                <td>{o.M}</td>
                                <td>{o.N}</td>
                                <td>
                                    <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="editar" onClick={() => cargarReferencia(o.I)}><img src="imagenes/edit.png" /></Button>
                                </td>
                                <td>
                                    <Button style={{ borderColor: 'maroon' }} size="sm" color="secondary" outline title="eliminar" onClick={() => eliminar(o)} ><img src="imagenes/menos.png" /></Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Modal size="lg" isOpen={capturando} toggle={() => setCapturando(false)}>
                <ModalHeader>
                    <h6 className='rojo'>{id == 0?  "nueva referencia":"editando referencia"}</h6>
                </ModalHeader>
                <Container>
                    <br/>
                    <Row>
                        <div className='col'>
                            <span>Titulo</span>
                            <Input size="sm" type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <span>Autor</span>
                            <Input size="sm" type="text" value={autor} onChange={(e) => setAutor(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <span>Tipo</span><br/>
                            <InputGroup>
                            <Input size="sm" type="select" value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                <option value={0}>Especifique</option>
                                {tipos.map((o,i)=> <option key={i} value={o.I}>{o.N}</option>)}
                            </Input>
                            <Button size="sm" color="secondary" outline onClick={agregarTipo} title="agregar tipo de referencia"><img src="imagenes/add.png" /></Button>

                            </InputGroup>
                        </div>
                        <div className='col-2'>
                            <span>Edicion</span>
                            <Input size="sm" type="text" value={edicion} onChange={(e) => setEdicion(e.target.value)} />
                        </div>
                        <div className='col-4'>
                            <span>Editorial</span>
                            <Input size="sm" type="text" value={editorial} onChange={(e) => setEditorial(e.target.value)} />
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            <Button size="sm" color="secondary" outline onClick={guardar}>Guardar</Button>
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
        </div>
    )
}

export const ProgramaSesiones = (props) => {
    const edoGlobal = useContext(AppContext);
    let [sesiones,setSesiones] = useState([]);
    let [temas, setTemas] = useState([]);
    let [actividades, setActividades] = useState([]);
    let [examenes, setExamenes] = useState([]);
    let [cambios, setCambios] = useState(1);
    let [asignacion, setAsignacion] = useState(1);
    let [seleccion, setSeleccion] = useState(false);

    useEffect( () => {
        let sesiones = [];
        let tema = {};
        let subtema = {};
        let actividad = {};
        cargarSesiones();
        temas = [];
        actividades = [];
        examenes = [];
        asignacion = 0;
        for (let i = 0; i < props.ejes.length; i++){
            for(let j = 0; j < props.ejes[i].Actividades.length; j++){
                actividad = props.ejes[i].Actividades[j];                
                if(actividad.Asignacion > asignacion)
                    asignacion = actividad.Asignacion;
                if(actividad.Tipo == 'E')
                    examenes.push({
                        eje:props.ejes[i].Id,
                        id:actividad.Id,
                        texto: actividad.Orden + ' ' + actividad.Titulo,
                        tipo:'E',
                        tipoAct:actividad.Tipo,
                        tema:0,
                        sel:false,
                        asignado:actividad.Asignado,
                        asignacion:actividad.Asignacion
                    });
                else
                  actividades.push({
                    eje:props.ejes[i].Id,
                    id:actividad.Id,
                    texto: actividad.Orden + ' ' + actividad.Titulo,
                    tipo:'A',
                    tipoAct:actividad.Tipo,
                    tema:0,
                    sel:false,
                    asignado:actividad.Asignado,
                    asignacion:actividad.Asignacion
                });
            }
            for(let j = 0; j < props.ejes[i].Temas.length; j++){
                tema = props.ejes[i].Temas[j];
                if(tema.Asignacion > asignacion)
                    asignacion = tema.Asignacion;
                temas.push({
                    eje:props.ejes[i].Id,
                    id:tema.Id,
                    texto: props.ejes[i].Orden + '.' + tema.Orden + '.' + tema.Texto,
                    tipo:'T',
                    tema:0,
                    sel:false,
                    asignado:tema.Asignado,
                    asignacion:tema.Asignacion
                });
                for(let k = 0; k < tema.SubTemas.length; k++){
                    subtema = tema.SubTemas[k];
                    if(subtema.Asignacion > asignacion)
                        asignacion = subtema.Asignacion;    
                    temas.push({
                        eje:props.ejes[i].Id,
                        id:subtema.Id,
                        texto: props.ejes[i].Orden + '.' + tema.Orden + '.' + subtema.Orden + '.' + subtema.Texto,
                        tema:tema.Id,
                        tipo:'S',
                        sel:false,
                        asignado:subtema.Asignado,
                        asignacion:subtema.Asignacion
                        });
                }
            }
        }
        setTemas(temas);
        setActividades(actividades);
        setExamenes(examenes);
        setAsignacion(asignacion + 1);
    },[props.programa.Sesiones]);

    async function cargarSesiones(){
        let url = `${edoGlobal.urlws}/programaIndicativo/sesiones/${props.pi}`;
        let resp = await fetch(url);
        const s = await resp.json();
        sesiones = [];
        for (let i = 0; i < s.length; i++)
           sesiones.push({sesion: s[i].Sesion, asignado:s[i].Asignada, seleccionado: false,renglones:1,renglon:1,asignacion:s[i].Asignacion, temas:s[i].Temas, actividades:s[i].Actividades});
        setSesiones(sesiones);
    }
    function sesionClick(i) {
        if(sesiones[i].asignado)
            return;
        seleccion = false;
        for (let j = 0; j < sesiones.length; j++){
            if(j <= i){
                if(!sesiones[j].asignado){
                    sesiones[j].seleccionado = true;
                    seleccion = true;                    
                }
            }
            else
                sesiones[j].seleccionado = false;    
        }
        setSeleccion(seleccion);
        setCambios(-cambios);
    }
    const exaSel = (t) => {
        let i = 0;
        for (let j = 0; j < examenes.length; j++)
            if(examenes[j].id == t.id){
                i = j;
                break;
            }
        for (let j = 0; j < examenes.length; j++){
            if(j <= i){
                if(!examenes[j].asignado){
                    examenes[j].sel = true;
                }
            }
            else
                examenes[j].sel = false;    
        }
        setCambios(-cambios);
    }
    const actSel = (t) => {
        let i = 0;
        let sels = 0;
        for (let j = 0; j < actividades.length; j++)
            if(actividades[j].id == t.id){
                i = j;
                break;
            }
        for (let j = 0; j < actividades.length; j++)
            if(actividades[j].sel && !actividades[j].asignado)
                sels++;
    
        if(actividades[i].sel && sels == 1){
            actividades[i].sel = false;
            setCambios(-cambios);
             return;
        }
    
        for (let j = 0; j < actividades.length; j++){
            if(j <= i){
                if(!actividades[j].asignado){
                    actividades[j].sel = true;
                }
            }
            else
                actividades[j].sel = false;    
        }
        setCambios(-cambios);
    }
    const temaSel = (t) => {
        let i = 0;
        let sels = 0;
        for (let j = 0; j < temas.length; j++){
            if(temas[j].id == t.id){
                i = j;
                break;
            }
        }
        for (let j = 0; j < temas.length; j++)
            if(temas[j].sel && !temas[j].asignado)
                sels++;

        if(temas[i].sel && sels == 1){
            temas[i].sel = false;
            setCambios(-cambios);
            return;
        }
    
        for (let j = 0; j < temas.length; j++){
            if(j <= i){
                if(!temas[j].asignado){
                    temas[j].sel = true;
                }
            }
            else
                temas[j].sel = false;    
        }
        setCambios(-cambios);
    }
    const ultimoTemSel = () => {
        let i = 0;
        for (let j = 0; j < temas.length; j++)
            if(temas[j].asignado)
                i = j;
        return i;
    }
    async function asignar(){
        let renglon = 1;
        let cantSesiones = 0;
        let cantTemas = 0;
        let cantActs = 0;
        let sesionesId = "";
        let temasId = "";
        let actividadesId = "";
        let sepa = "";
        let j = 0;
        let esExamen = false;

        for (j = 0; j < sesiones.length; j++)
            if(sesiones[j].seleccionado){
                cantSesiones++;
                sesionesId = sesionesId + sepa + sesiones[j].sesion;
                sepa = "-";
            }

        sepa = "";
        for (j = 0; j < temas.length; j++)
            if(temas[j].sel){
                cantTemas++;
                temasId = temasId + sepa + temas[j].id;
                sepa = "-";
            }
        sepa = "";
        for (j = 0; j < actividades.length; j++)
            if(actividades[j].sel){
                if(actividades[j].tipoAct == "E")
                    esExamen = true;
                cantActs++;
                actividadesId = actividadesId + sepa + actividades[j].id;
                sepa = "-";
            }
        for (j = 0; j < examenes.length; j++)
            if(examenes[j].sel){
                cantActs++;
                esExamen = true;
                actividadesId = actividadesId + sepa + examenes[j].id;
                sepa = "-";
            }
        if(cantTemas == 0 && cantActs > 0 && !esExamen){
            j = ultimoTemSel();
            temas[j].sel = true;
            cantTemas = 1;
            temasId = temasId + temas[j].id;
        }
        if(cantSesiones == 0 || (cantTemas + cantActs) == 0)
            return;
        /*
        if(cantSesiones > 1 && cantTemas > 1){
            Swal.fire('Atencion', 'debe seleccionar una sola sesion o un solo tema', 'error');
            return;
        }
        if(cantSesiones > 1 && cantActs > 1){
            Swal.fire('Atencion', 'debe seleccionar una sola sesion o una sola actividad', 'error');
            return;
        }
        */
        const dato = {
            Pi: props.pi, Asignacion: asignacion,
            Temas: temasId, Sesiones: sesionesId,
            Actividades: actividadesId
        }

        let url = `${edoGlobal.urlws}/programaIndicativo/wsGuardarSesion`;
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        if (res !== "ok") {
            Swal.fire('Atencion', res, 'error');
            return;
        }
        let actsSel = [];
        for (j = 0; j < actividades.length; j++)
            if(actividades[j].sel){
                actividades[j].sel = false;
                actividades[j].asignado = true;
                actividades[j].asignacion = asignacion;
                actsSel.push(actividades[j].texto);
            }
        for (j = 0; j < examenes.length; j++)
            if(examenes[j].sel){
                examenes[j].sel = false;
                examenes[j].asignado = true;
                examenes[j].asignacion = asignacion;
                actsSel.push(examenes[j].texto);
            }    
        let temasSel = [];
        for (j = 0; j < temas.length; j++)
                if(temas[j].sel){
                    temas[j].sel = false;
                    temas[j].asignado = true;
                    temas[j].asignacion = asignacion;
                    temasSel.push(temas[j].texto);
                }
    
        for (j = 0; j < sesiones.length; j++)
            if(sesiones[j].seleccionado){
                sesiones[j].seleccionado = false;
                sesiones[j].asignado = true;
                sesiones[j].temas = temasSel;
                sesiones[j].actividades = actsSel;
                sesiones[j].renglon = renglon++;
                sesiones[j].renglones = cantSesiones;
                sesiones[j].asignacion = asignacion;
            }   
        setAsignacion(asignacion + 1);
    }
    async function desasignar(){
        let asigna = asignacion - 1;        
        let j = 0;
        let url = `${edoGlobal.urlws}/programaIndicativo/deshacerSesion?pi=${props.pi}&asignacion=${asigna}`;
        const resp = await fetch(url);
        const res = await resp.text();
        if (res !== "ok") {
            Swal.fire('Atencion', res, 'error');
            return;
        }

        for (j = 0; j < actividades.length; j++){
            actividades[j].sel = false;
            if(actividades[j].asignado && actividades[j].asignacion == asigna){
                actividades[j].asignado = false;
                actividades[j].asignacion = 0;
            }
        }
        for (j = 0; j < examenes.length; j++){
            examenes[j].sel = false;
            if(examenes[j].asignado && examenes[j].asignacion == asigna){
                examenes[j].asignado = false;
                examenes[j].asignacion = 0;
            }
        }
        for (j = 0; j < temas.length; j++){
            temas[j].sel = false;
            if(temas[j].asignado && temas[j].asignacion == asigna){
                temas[j].asignado = false;
                temas[j].asignacion = 0;
            }
        }

        for (j = 0; j < sesiones.length; j++){
            sesiones[j].seleccionado = false;
            if(sesiones[j].asignado && sesiones[j].asignacion == asigna){
                sesiones[j].asignado = false;
                sesiones[j].temas = [];
                sesiones[j].actividades = [];
                sesiones[j].renglon = 0;
                sesiones[j].renglones = 0;
                sesiones[j].asignacion = 0;
            }   
        }
        setAsignacion(asigna);
    }
    let claseSel = 'color-warning';
    let renSel = 0;
    return (
        <div>
            <Row>
                <div className='col-4'>
                    <Card style={{padding:4}}>
                    <h6 className='rojo'>Temas y subtemas</h6>
                    <div style={{ overflowY: 'scroll', height: 570 }}>
                        {props.ejes.map((e, ei) =>
                            <div key={ei} >
                                <h6>{e.Orden + ' - ' + e.Texto}</h6>
                                <ul>
                                    {temas.filter(t => t.eje == e.Id).map((t, ti) =>
                                        <li key={ti}>
                                            <h6>
                                                {t.tipo == 'S' && <span>&nbsp;&nbsp;&nbsp;</span>}
                                                {t.asignado ?
                                                    <Badge style={{ width:300, color: 'white', backgroundColor: 'maroon', textAlign:'left' }} >{t.texto}</Badge>
                                                    :
                                                    <Badge style={{ width:300, textAlign:'left'}} onClick={() => temaSel(t)} className='cardTema' color={t.sel ? 'success' : 'secondary'}>{t.texto}</Badge>
                                                }
                                            </h6>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    </Card>
                </div>
                <div className='col-4'>
                    <Card style={{padding:4}}>
                    <h6 className='rojo'>Examenes</h6>
                    <div>
                        <ul>
                        {examenes.map((t, ti) =>
                            <li key={ti}>
                                <h6>
                                {t.asignado ?
                                <Badge style={{textAlign:'left', width:300, color: 'white', backgroundColor: 'maroon' }} >{t.texto}</Badge>
                                :
                                <Badge style={{textAlign:'left', width:300}} onClick={() => exaSel(t)} className='cardTema' color={t.sel ? 'success' : 'secondary'}>{t.texto}</Badge>
                                }
                                </h6>
                                </li>
                        )}
                        </ul>
                    </div>
                    </Card>
                    <br/>
                    <Card style={{padding:4}}>
                    <h6 className='rojo'>Actividades</h6>
                    <div style={{ overflowY: 'scroll', height: 500 }}>
                        {props.ejes.map((e, ei) =>
                            <div key={ei} >
                                <h6>{e.Orden + ' - ' + e.Texto}</h6>
                                <ul>
                                    {actividades.filter(t => t.eje == e.Id ).map((t, ti) =>
                                        <li key={ti}>
                                            <h6>
                                                {t.asignado ?
                                                    <Badge style={{textAlign:'left', width:300, color: 'white', backgroundColor: 'maroon' }} >{t.texto}</Badge>
                                                    :
                                                    <Badge style={{textAlign:'left', width:300}} onClick={() => actSel(t)} className='cardTema' color={t.sel ? 'success' : 'secondary'}>{t.texto}</Badge>
                                                }
                                            </h6>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    </Card>
                </div>
                <div className='col'>
                    <Card style={{padding:4}}>
                    <h6 className='rojo'>Sesiones</h6>
                    <div>
                        <b>&nbsp;&nbsp;Sesion &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button size="sm" className="botonLince" color="secondary" outline onClick={asignar}>Asignar</Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        {asignacion > 1 && <Button size="sm" color="secondary" outline onClick={desasignar}>Deshacer</Button>}
                    </div>
                    <div style={{height:4}}></div>
                    <div style={{overflowY:'scroll',height:530}}>
                        {sesiones.map((o,i) => <span key={i} onClick={() => sesionClick(i)}><Sesion sesion={o} indice={i} /></span>)}
                    </div>
                    </Card>
                </div>
            </Row>
        </div>
    )
}
const Sesion = ({sesion,indice}) => {
    let temas = '';
    let actividades = '';
    let color = 'light';
    if(sesion.seleccionado)
        color = 'secondary'
    if (sesion.asignado) {
        color = 'warning';
        if (sesion.temas.length > 0) {
            let sepa = '';
            for (let i = 0; i < sesion.temas.length; i++) {
                temas = temas + sepa + '&#10151;  ' + sesion.temas[i];
                sepa = '<br/>';
            }
        }
        if (sesion.actividades.length > 0) {
            let sepa = '';
            for (let i = 0; i < sesion.actividades.length; i++) {
                actividades = actividades + sepa + '&#10151;  ' + sesion.actividades[i];
                sepa = '<br/>';
            }
        }
    }
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    let clase = "card-body bg-" + color + " col-10";
    let idToolTip = 'tema-' + sesion.sesion;
    return(
            <div className="card bg-light mb-1 col-12">
                <div className="row"  id={idToolTip}>
                    <div className="card-header col-2 border bg-light">
                       <b className='rojo'>{sesion.sesion}</b>
                    </div>
                    <div className={clase}>
                        {temas !== "" &&  actividades === "xxx" && 
                                <Tooltip style={{backgroundColor:'white', color:'black', border:'1px solid maroon'}} isOpen={tooltipOpen} toggle={toggle} target={idToolTip} autohide={false}>
                                    <div dangerouslySetInnerHTML={{ __html: '<b>TEMAS</b> <hr style={{borderColor  :"maroon"}}/>' + temas }} />
                                </Tooltip>
                        }
                        {temas !== "" && 
                            <div>
                                <b className='rojo'>Temas</b>
                                <b><div dangerouslySetInnerHTML={{ __html: temas }} /></b>
                            </div>
                        }
                        {actividades !== "" && 
                            <div>
                                <b className='rojo'>Actividades</b>
                                <b><div dangerouslySetInnerHTML={{ __html: actividades }} /></b>
                            </div>
                        }
                    </div>
                </div>
            </div>
    )
}