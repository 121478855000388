import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Input, Row, Table, Button, Spinner, Modal, ModalHeader, ModalBody, Card, CardBody, Container, Badge } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Grupos } from './Grupos';
import { GrupoAsistencia } from './GrupoAsistencia';
import { GrupoTemas } from './GrupoTemas';
import { GrupoTrabajos } from './GrupoTrabajos';
import { GrupoAnotaciones } from './GrupoAnotaciones';
import { GrupoAlumno } from './GrupoAlumno';
import { GrupoDocumentos } from './GrupoDocumentos';
import { GrupoAnuncios } from './GrupoAnuncios';
import { ProgramaCompleto } from './ProgramaCompleto';
import { GrupoSesiones } from './GrupoSesiones';

export const Grupo = (props) => {
    const edoGlobal = useContext(AppContext);
    let [alumnos, setAlumnos] = useState([]);
    let [alumnosEq, setAlumnosEq] = useState([]);
    let [alumnosEditEq, setAlumnosEditEq] = useState([]);
    let [ejes, setEjes] = useState([]);
    let [unidades, setUnidades] = useState([]);
    let [calificaciones, setCalificaciones] = useState([]);
    let [equipos, setEquipos] = useState([]);
    let [alumnosImp, setAlumnosImp] = useState([]);
    let [periodo, setPeriodo] = useState("");
    let [nomEquipo, setNomEquipo] = useState("");
    let [grupoImp, setGrupoImp] = useState({});
    let [alumno, setAlumno] = useState({});
    let [asistencias, setAsistencias] = useState([]);
    let [lista, setLista] = useState([]);
    let [fecha, setFecha] = useState("");
    let [indice, setIndice] = useState(-1);
    let [matricula, setMatricula] = useState("");
    let [nombre, setNombre] = useState("");
    let [apePat, setApePat] = useState("");
    let [apeMat, setApeMat] = useState("");
    let [texto, setTexto] = useState("");
    let [opcion, setOpcion] = useState(0);
    let [equipoAct, setEquipoAct] = useState({Id:'',Nombre:''});
    let [verModalGrupos, setVerModalGrupos] = useState(false);
    let [verModalAlumnosEq,setVerModalAlumnosEq] = useState(false);
    let [verModalAlumno, setVerModalAlumno] = useState(false);
    let [verModalMasivo, setVerModalMasivo] = useState(false);
    let [nuevoAlumno, setNuevoAlumno] = useState(false);
    let [cambios, setCambios] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [capturarP1, setCapturarP1] = useState(false);
    const [capturarP2, setCapturarP2] = useState(false);
    const [capturarP3, setCapturarP3] = useState(false);
    const [capturarOrd, setCapturarOrd] = useState(false);
    const [capturarFin, setCapturarFin] = useState(false);
    const [capturarExt, setCapturarExt] = useState(false);

    useEffect(() => {
        (async () => {
            setOpcion(1);
            cargarAlumnos();
        })();
    }, []);

    async function cargarAlumno(mat) {
        setCargando(true);
        let url = `${edoGlobal.urlws}/alumno/cargar/${mat}?inst=${edoGlobal.institucion}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setAlumno(a);
        if (a.Id === "0") {
            setNombre("");
            setApePat("");
            setApeMat("");
            setNuevoAlumno(true);
        } else {
            setNombre(a.Nombre);
            setApePat(a.ApePat);
            setApeMat(a.ApeMat);
            setNuevoAlumno(false);
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Confirme',
                text: "¿ agregar " + a.Nombre + '' + a.ApePat + ' ' + a.ApeMat + " ?",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.value) {
                    agregarAlumno();
                }
                else
                    iniciarAlumno();
            })
        }
    }
    async function agregarAlumno() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/agregarAlumno?grupo=${props.grupo.Id}&alumno=${matricula}`;
        let resp = await fetch(url);
        const res = await resp.text();
        setCargando(false);
        if (res == "ok") {
            alumnos = [];
            cargarAlumnos();
            iniciarAlumno();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    function iniciarAlumno() {
        setMatricula("");
        setNombre("");
        setApePat("");
        setApeMat("");
        setNuevoAlumno(false);
    }
    async function guardarAlumno() {
        const dato = {
            Id: matricula, Institucion: edoGlobal.institucion,
            Nombre: nombre, ApePat: apePat, ApeMat: apeMat,
            Pwd: matricula, Estado: '', Municipio: '', Sexo: ''
        }
        let url = `${edoGlobal.urlws}/alumno/wsguardarGrupo?grupo=${props.grupo.Id}`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") {
            alumnos = [];
            cargarAlumnos();
            iniciarAlumno();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    async function cargarAlumnos() {
        if (alumnos.length !== 0)
            return;
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/alumnos/${props.grupo.Id}`;
        let resp = await fetch(url);
        const a = await resp.json();
        url = `${edoGlobal.urlws}/grupo/equipos/${props.grupo.Id}`;
        resp = await fetch(url);
        const e = await resp.json();
        url = `${edoGlobal.urlws}/grupo/cargar/${props.grupo.Id}`;
        resp = await fetch(url);
        const g = await resp.json();
        url = `${edoGlobal.urlws}/unidad/unidades/${props.grupo.IdMateria}?version=${props.grupo.Version}`;
        resp = await fetch(url);
        const u = await resp.json();
        url = `${edoGlobal.urlws}/programaIndicativo/ejes?pi=${props.grupo.Programa}`;
        resp = await fetch(url);
        const ej = await resp.json();
        setCargando(false);
        setEquipos(e);
        setPeriodo(g.Periodo);
        setUnidades(u);
        setEjes(ej);
        setCalificaciones(a.map((o,i)=>{ return {P1:o.P1, P2:o.P2, P3:o.P3, Ord:o.Ord, Fin:o.Fin, Ext:o.Ext}}));
        setAlumnos(a);
    }
    function editarAlumnosGrupo(eq){
        setEquipoAct(eq);
        setVerModalAlumnosEq(true);
        setAlumnosEditEq(alumnos.filter((e)=> e.Eq == eq.Id || e.Eq == 0));
        setCambios(0);
    }
    async function cerrarModalAlumnosEq(){
        setVerModalAlumnosEq(false);
        if(cambios > 0){
            setCambios(0);
            setCargando(true);
            let url = `${edoGlobal.urlws}/grupo/alumnos/${props.grupo.Id}`;
            let resp = await fetch(url);
            const a = await resp.json();
            setCargando(false);
            setAlumnos(a);
        }
    }
    function alumnosEquipo(eq){
        setNomEquipo(eq.Nombre);
        setAlumnosEq(alumnos.filter((e)=> e.Eq == eq.Id));
    }
    async function cambiarEquipoAlu(i,agregar){
        let equipo = agregar ? equipoAct.Id : 0;
        let url = `${edoGlobal.urlws}/alumno/asignarEquipo/${alumnosEditEq[i].Id}?grupo=${props.grupo.Id}&equipo=${equipo}`;
        setCargando(true);
        const resp = await fetch(url,{
            method: 'POST'
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            if(agregar){
                alumnosEditEq[i].Eq = equipoAct.Id;
                alumnosEditEq[i].NEq = equipoAct.NEq;    
            }else{
                alumnosEditEq[i].Eq = 0;
                alumnosEditEq[i].NEq = "";    
            }
            setCambios(cambios + 1);    
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cambiarEquipo(eq,indice) {
        alumnos[indice].Eq = eq;
        for (let i = 0; i < equipos.length; i++)
            if (equipos[i].Id == eq)
                alumnos[indice].NEq = equipos[i].Nombre;
        setCambios(cambios + 1);
    }
    async function cargarEquipos() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/equipos/${props.grupo.Id}`;
        let resp = await fetch(url);
        const e = await resp.json();
        setCargando(false);
        setEquipos(e);
    }
    async function cargarAsistencias() {
        setOpcion(2);
    }
    function capturarCalif(){
        setOpcion(5);
        //setCalificaciones(alumnos.map((o,i)=>{ return {P1:o.P1, P2:o.P2, P3:o.P3, Ord:o.Ord, Fin:o.Fin, Ext:o.Ext}}));
    }
    function cambiarCalif(p,i,c){
        if(p === 1)
           calificaciones[i].P1 = c;
        else if(p === 2)
           calificaciones[i].P2 = c;
        else if(p === 3)
           calificaciones[i].P3 = c;
        else if(p === 4)
           calificaciones[i].Ord = c;
        else if(p === 5)
        calificaciones[i].Fin = c;
        else
           calificaciones[i].Ext = c;
        setCambios(cambios + 1);
    }
    async function guardarCalif(){
        let url = `${edoGlobal.urlws}/grupo/wsguardarCalif/${props.grupo.Id}`;
        let dato = [];
        for(let i = 0; i < alumnos.length; i++){
            dato.push({
                Id:alumnos[i].Id,
                P1:calificaciones[i].P1,
                P2:calificaciones[i].P2,
                P3:calificaciones[i].P3,
                Ord:calificaciones[i].Ord,
                Fin:calificaciones[i].Fin,
                Ext:calificaciones[i].Ext,
            });    
        }
        const json = JSON.stringify(dato);
        setCargando(true);
        let resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        if(res === "ok"){
            url = `${edoGlobal.urlws}/grupo/alumnos/${props.grupo.Id}`;
            resp = await fetch(url);
            const a = await resp.json();
            setAlumnos(a);    
            setCalificaciones(a.map((o,i)=>{ return {P1:o.P1, P2:o.P2, P3:o.P3, Ord:o.Ord, Fin:o.Fin, Ext:o.Ext}}));
            setCargando(false);
        }
        else{
            setCargando(false);
            Swal.fire('Atencion',res,'error');
        }
    }
    async function actualizarPuntaje(){
        let url = `${edoGlobal.urlws}/grupo/calculaPuntajeGrupo?grupo=${props.grupo.Id}&parcial=0`;
        setCargando(true);
        let resp = await fetch(url);
        setCargando(false);
        const res = await resp.text();
        if(res === "ok"){
            alumnos = [];
            cargarAlumnos();
        }
        else{
            Swal.fire('Atencion',res,'error');
        }
    }
    function calificacionesPDF(){
        let url = `${edoGlobal.urlws}/grupo/imprimirCalif/${props.grupo.Id}`;
        window.open(url, '', '');    
    }
    async function grupoSeleccionado(gpo) {
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/alumnos/${gpo.Id}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setCargando(false);
        setAlumnosImp(a);
        setGrupoImp(gpo);
    }
    async function importarAlumnos(gpo) {
        setCargando(true);
        let url = `${edoGlobal.urlws}/grupo/importarAlumnos/${props.grupo.Id}?origen=${gpo}`;
        let resp = await fetch(url);
        const res = await resp.text();
        if (res == "ok")
            cargarAlumnos();
        else
            Swal.fire('Atencion', res, 'error');
        setCargando(false);
        setVerModalGrupos(false);
    }
    async function procesarTexo() {
        const dato = {
            texto
        }
        let url = `${edoGlobal.urlws}/grupo/agregarAlumnos/${props.grupo.Id}`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res.substring(0, 3) !== "Err") {
            alumnos = [];
            Swal.fire('Atencion', "Se agregaron " + res + " alumnos", 'success');
            setVerModalMasivo(false);
            cargarAlumnos();
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    function editarAlumno(a, i) {
        setMatricula(a.Id);
        setIndice(i)
        setOpcion(10);
    }
    async function eliminarAlumno(a,i) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ eliminar a " + a.Nombre + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then(async(result) => {
            if (result.value) {
                let url = `${edoGlobal.urlws}/grupo/eliminarAlumno?grupo=${props.grupo.Id}&alumno=${a.Id}`;
                setCargando(true);
                const req = await fetch(url, {
                    method: 'POST' // or 'PUT'
                })
                const res = await req.text();
                setCargando(false);
                if (res !== "ok") {
                    Swal.fire('Atencion', res, 'error');
                } else {
                    alumnos = [];
                    cargarAlumnos();
                }        
            }
        })
    }
    async function agregarEquipo() {
        const { value: equipo } = await Swal.fire({
            title: 'Agregar equipo',
            input: 'text',
            inputValue: '',
            inputPlaceholder: 'Nombre del equipo'
        })

        if (equipo != "") {
            let url = `${edoGlobal.urlws}/grupo/guardarEquipo/0?grupo=${props.grupo.Id}&nombre=${equipo}`;
            setCargando(true);
            const req = await fetch(url, {
                method: 'POST' // or 'PUT'
            })
            const res = await req.text();
            setCargando(false);
            if (res !== "ok") {
                Swal.fire('Atencion', res, 'error');
            } else {
                cargarEquipos();
            }
        }
    }
    async function eliminarEquipo(o,i) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ eliminar el equipo " + o.Nombre + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then(async(result) => {
            if (result.value) {
                let url = `${edoGlobal.urlws}/grupo/eliminarEquipo/${o.Id}`;
                setCargando(true);
                const req = await fetch(url, {
                    method: 'POST' // or 'PUT'
                })
                const res = await req.text();
                setCargando(false);
                if (res !== "ok") {
                    Swal.fire('Atencion', res, 'error');
                } else {
                    cargarEquipos();
                }        
            }
        })
    }
    const estiloAlumnos = { display: opcion !== 1 ? 'none' : 'inline' };
    const estiloAsistencias = { display: opcion !== 2 ? 'none' : 'inline' };
    const estiloTemas = { display: opcion !== 3 ? 'none' : 'inline' };
    const estiloTrabajos = { display: opcion !== 4 ? 'none' : 'inline' };
    const estiloCalif = { display: opcion !== 5 ? 'none' : 'inline' };
    const estiloAnotaciones = { display: opcion !== 6 ? 'none' : 'inline' };
    const estiloDocs = { display: opcion !== 7 ? 'none' : 'inline' };
    const estiloAnuncios = { display: opcion !== 8 ? 'none' : 'inline' };
    const estiloEjes = { display: opcion !== 9 ? 'none' : 'inline' };
    const estiloAlumno = { display: opcion !== 10 ? 'none' : 'inline' };
    let equipo = 0;
    if (indice >= 0)
        equipo = alumnos[indice].Eq;
    return (
        <div>
            <h6>Grupo {props.grupo.Gpo} - {props.grupo.Carrera}</h6>
            <br />
            <div>
                <Row>
                    <div className='col-1'>
                        <Table size="sm" hover>
                            <tbody>
                                <tr onClick={cargarAlumnos} className='cardGrupo'>
                                    <td>
                                        <Button  size="sm" color="secondary" outline title="alumnos">
                                            <img src="/imagenes/alumnos.png" alt="alumnos" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Alumnos</span></td>
                                </tr>
                                <tr onClick={cargarAsistencias} className='cardGrupo'>
                                    <td>
                                        <Button  size="sm" color="secondary" outline title="asistencia">
                                            <img src="/imagenes/asistencia.png" alt="asistencia" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Asistencia</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(3)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="temas">
                                            <img src="/imagenes/temas.png" alt="temas" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><span className="rojo">Avance diario</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(9)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="Programa indicativo">
                                            <img src="/imagenes/unidades.png" alt="ejes" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><span className="rojo">Programa indicativo</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(4)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="trabajos">
                                            <img src="/imagenes/trabajos.png" alt="trabajos" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Actividades</span></td>
                                </tr>
                                <tr onClick={capturarCalif} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="calificaciones">
                                            <img src="/imagenes/calif.png" alt="calificaciones" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Calificaciones</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(6)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="anotaciones">
                                            <img src="/imagenes/notas.png" alt="anotaciones" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Anotaciones</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(7)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="documentos">
                                            <img src="/imagenes/libros.png" alt="documentos" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Documentos</span></td>
                                </tr>
                                <tr onClick={() => setOpcion(8)} className='cardGrupo'>
                                    <td>
                                        <Button size="sm" color="secondary" outline title="anuncios">
                                            <img src="/imagenes/anuncios.png" alt="anuncios" />
                                            <br />
                                        </Button>
                                    </td>
                                    <td><br /><span className="rojo">Anuncios</span></td>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                    </div>
                    <div className='col-1'></div>
                    <div className='col-9'>
                        <div className='col'>
                            <div style={estiloAlumnos}>
                                <Container>
                                    <Row>
                                        <div className='col'>
                                        <Card style={{padding:10}}>
                                            <h6>
                                                &nbsp;{alumnos.length}&nbsp; Alumnos &nbsp;&nbsp;
                                                {props.grupo.Abierto ? <Button onClick={() => setVerModalAlumno(true)} size="sm" outline color="secondary"><img src="/imagenes/add.png" alt="agregar" title="agregar" /></Button> : null}
                                                <span>&nbsp;&nbsp; </span>
                                                {props.grupo.Abierto && alumnos.length === 0 ? <Button onClick={() => setVerModalGrupos(true)} size="sm" outline color="secondary"><img src="/imagenes/zoom.png" alt="importar" />Importar alumnos de otro grupo</Button> : null}
                                                <span>&nbsp;&nbsp; </span>
                                                {props.grupo.Abierto ? <Button onClick={() => setVerModalMasivo(true)} size="sm" outline color="secondary"><img src="/imagenes/add.png" alt="agregar" /> Agregar alumnos</Button> : null}
                                            </h6>
                                            <div style={{ height: 450, overflow: 'auto' }}>
                                                <Table size="sm" hover striped>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 100 }}>Matricula</th>
                                                            <th>Nombre</th>
                                                            <th>Equipo</th>
                                                            <th className='celdaBoton'></th>
                                                            <th className='celdaBoton'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {alumnos.map((o, i) => {
                                                            return (
                                                                <tr key={i} className="cardAlumno">
                                                                    <td><span className='rojo'>{o.Id}</span></td>
                                                                    <td>{o.Nombre}</td>
                                                                    <td>{o.NEq}</td>
                                                                    <td><img onClick={() => editarAlumno(o, i)} src="/imagenes/edit.png" alt="editar" title="editar" /></td>
                                                                    <td><img onClick={() => eliminarAlumno(o,i)} src="/imagenes/menos.png" alt="eliminar" title="eliminar" /></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            </Card>
                                        </div>
                                        <div className='col-4'>
                                        <Card style={{padding:10}}>
                                            <h6>
                                                &nbsp;{equipos.length}&nbsp; Equipos &nbsp;&nbsp;
                                                {props.grupo.Abierto ? <Button onClick={agregarEquipo} size="sm" outline color="secondary"><img src="/imagenes/add.png" alt="agregar" title="agregar" /></Button> : null}
                                            </h6>
                                            <div style={{ height: 450, overflow: 'auto' }}>
                                                <Table size="sm" hover striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th className='celdaBoton'></th>
                                                            <th className='celdaBoton'></th>
                                                            <th className='celdaBoton'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {equipos.map((o, i) => {
                                                            return (
                                                                <tr key={i}  className="cardAlumno">
                                                                    <td>{o.Nombre}</td>
                                                                    <td><img onClick={() => alumnosEquipo(o)} src="/imagenes/list.png" alt="ver alumnos" title="ver alumnos" /></td>
                                                                    <td><img onClick={() => editarAlumnosGrupo(o)} src="/imagenes/edit.png" alt="editar alumnos" title="editar alumnos" /></td>
                                                                    <td><img onClick={() => eliminarEquipo(o,i)} src="/imagenes/menos.png" alt="eliminar" title="eliminar" /></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                                <Table size="sm" hover striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Equipo : {nomEquipo}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {alumnosEq.map((o, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{o.Nombre}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            </Card>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloAsistencias}>
                                <Card style={{padding:4}}>
                                    <GrupoAsistencia grupo={props.grupo} setCargando={setCargando} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloTemas}>
                                {
                                //<GrupoTemas grupo={props.grupo} unidades={unidades} setCargando={setCargando} />
                                }
                                <Card style={{padding:10}}>
                                    <GrupoSesiones grupo={props.grupo} setCargando={setCargando} consulta={false} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloEjes}>
                                {
                                    /*
                                <ul>
                                    {ejes.map((o,i)=><Eje key={i} eje={o} />)}
                                </ul>
                                    */
                                }
                                <ProgramaCompleto ejes={ejes} />
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloTrabajos}>
                                <Card style={{padding:4}}>
                                    <GrupoTrabajos grupo={props.grupo} unidades={unidades} equipos={equipos} setCargando={setCargando} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloAnotaciones}>
                                <Card style={{padding:4}}>
                                    <GrupoAnotaciones grupo={props.grupo} setCargando={setCargando} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloDocs}>
                                <Card style={{padding:4}}>
                                    <GrupoDocumentos grupo={props.grupo} periodo={periodo} setCargando={setCargando} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloAnuncios}>
                                <Card style={{padding:4}}>
                                    <GrupoAnuncios grupo={props.grupo} setCargando={setCargando} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloAlumno}>
                                <Card style={{padding:10}}>
                                    <GrupoAlumno alumnos={alumnos} grupo={props.grupo} equipos={equipos} matricula={matricula} equipo={equipo} setCargando={setCargando} cambiarEquipo={cambiarEquipo} regresar={()=> setOpcion(1)} />
                                </Card>
                            </div>
                        </div>
                        <div className='col'>
                            <div style={estiloCalif}>
                                <Card style={{padding:5}}>
                                <table>
                                    <tr>
                                        <td>
                                            {props.grupo.Abierto && <Button size="sm" color="secondary" outline onClick={guardarCalif}>Guardar</Button>} 
                                        </td>
                                        <td><span>&nbsp;&nbsp;</span></td>
                                        <td><Button size="sm" color="secondary" outline onClick={calificacionesPDF}><img src="imagenes/pdf.png" alt="pdf" title="ver PDF" /></Button></td>
                                        <td>{props.grupo.Abierto && <Button onClick={actualizarPuntaje} color="secondary" size="sm" outline> <img src="imagenes/refresh.png" alt="puntaje" title="actualizar puntaje" /></Button>}</td>
                                    </tr>
                                </table>
                                <div style={{ height: 450, overflow: 'auto' }}>
                                    <Table size="sm" hover striped>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 100 }}>Matricula</th>
                                                <th>Nombre</th>
                                                <th className='numeroCeldaCh'>Actividades 1er</th>
                                                <th className='numeroCeldaCh'>Actividades 2do</th>
                                                <th className='numeroCeldaCh'>Actividades 3er</th>
                                                <th className='numeroCeldaCh'>
                                                    {props.grupo.Abierto && <input type="checkbox" value={capturarP1} onChange={e => setCapturarP1(e.target.checked)}/> }
                                                    1er
                                                </th>
                                                <th className='numeroCeldaCh'>
                                                    {props.grupo.Abierto && <input type="checkbox" value={capturarP2} onChange={e => setCapturarP2(e.target.checked)}/> }
                                                    2do
                                                </th>
                                                <th className='numeroCeldaCh'>
                                                    {props.grupo.Abierto && <input type="checkbox" value={capturarP3} onChange={e => setCapturarP3(e.target.checked)}/> }
                                                    3er
                                                </th>
                                                <th className='numeroCeldaCh'>
                                                    {props.grupo.Abierto && <input type="checkbox" value={capturarOrd} onChange={e => setCapturarOrd(e.target.checked)}/>}
                                                    Ord.
                                                </th>
                                                <th className='numeroCeldaCh'>
                                                    {props.grupo.Abierto && <input type="checkbox" value={capturarFin} onChange={e => setCapturarFin(e.target.checked)}/>}
                                                    Final
                                                </th>
                                                <th className='numeroCeldaCh'>
                                                    <input type="checkbox" value={capturarExt} onChange={e => setCapturarExt(e.target.checked)}/> 
                                                    Ext.
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {alumnos.length == calificaciones.length && alumnos.map((o, i) => {
                                                return (
                                                    <tr key={i} className='cardAlumno'>
                                                        <td><span className='rojo'>{o.Id}</span></td>
                                                        <td>{o.Nombre}</td>
                                                        <td className='numeroCeldaCh'><span>{o.Act1}</span></td>
                                                        <td className='numeroCeldaCh'><span>{o.Act2}</span></td>
                                                        <td className='numeroCeldaCh'><span>{o.Act3}</span></td>
                                                        <td className='numeroCeldaCh'>{capturarP1 ? <Input size="sm" value={calificaciones[i].P1} onChange={(e)=>cambiarCalif(1,i,e.target.value)}/> : <span>{calificaciones[i].P1}</span>}</td>
                                                        <td className='numeroCeldaCh'>{capturarP2 ? <Input size="sm" value={calificaciones[i].P2} onChange={(e)=>cambiarCalif(2,i,e.target.value)}/> : <span>{calificaciones[i].P2}</span>}</td>
                                                        <td className='numeroCeldaCh'>{capturarP3 ? <Input size="sm" value={calificaciones[i].P3} onChange={(e)=>cambiarCalif(3,i,e.target.value)}/> : <span>{calificaciones[i].P3}</span>}</td>
                                                        <td className='numeroCeldaCh'>{capturarOrd ? <Input size="sm" value={calificaciones[i].Ord} onChange={(e)=>cambiarCalif(4,i,e.target.value)}/> : <span>{calificaciones[i].Ord}</span>}</td>
                                                        <td className='numeroCeldaCh'>{capturarFin ? <Input size="sm" value={calificaciones[i].Fin} onChange={(e)=>cambiarCalif(5,i,e.target.value)}/> : <span>{calificaciones[i].Fin}</span>}</td>
                                                        <td className='numeroCeldaCh'>{capturarExt ? <Input size="sm" value={calificaciones[i].Ext} onChange={(e)=>cambiarCalif(6,i,e.target.value)}/> : <span>{calificaciones[i].Ext}</span>}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>                                    
                                </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" className='rojo' />
                </div>
                :
                null
            }
            <Modal size="lg" isOpen={verModalAlumnosEq} toggle={cerrarModalAlumnosEq}>
                <ModalHeader>
                    <span>Alumnos del equipo</span>
                </ModalHeader>
                <ModalBody>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Matricula</th>
                                <th>Nombre</th>
                                <th className='celdaBoton'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {alumnosEditEq.map((o, i) => {
                                return (
                                    <tr key={i} >
                                        <td><span className='rojo'>{o.Id}</span></td>
                                        <td>{o.Nombre}</td>
                                        <td><input type="checkbox" checked={o.Eq == equipoAct.Id} onChange={e => cambiarEquipoAlu(i,e.target.checked)}/></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={verModalMasivo} toggle={() => setVerModalMasivo(false)}>
                <ModalHeader>
                    <span>Agregar alumnos</span>
                </ModalHeader>
                <ModalBody>
                    <Input style={{ height: 400 }} type="textarea" value={texto} onChange={(e) => setTexto(e.target.value)} />
                    <br />
                    <Button size="sm" color="secondary" outline onClick={procesarTexo}>Aceptar</Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={verModalAlumno} toggle={() => setVerModalAlumno(false)}>
                <ModalHeader>
                    <span>Agregar alumnos</span>
                </ModalHeader>
                <Container>
                    <Row>
                        <div className='col-3'>
                            <span>Matricula</span>
                            <Input size="sm" value={matricula} onKeyPress={(e) => { if (e.which == 13) cargarAlumno(matricula); }} onChange={(e) => setMatricula(e.target.value)} />
                        </div>
                        <div className='col'>
                            <span>Nombre</span>
                            <Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className='col-6'>
                            <span>Primer Apellido</span>
                            <Input size="sm" value={apePat} onChange={(e) => setApePat(e.target.value)} />
                        </div>
                        <div className='col-6'>
                            <span>Segundo Apellido</span>
                            <Input size="sm" value={apeMat} onChange={(e) => setApeMat(e.target.value)} />
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className='col-2'></div>
                        <div className='col'>
                            {
                                nuevoAlumno ? <Button size="sm" color="secondary" outline onClick={() => guardarAlumno()}>Agregar alumno</Button> : null
                            }
                        </div>
                    </Row>
                </Container>
                <br />
            </Modal>
            <Modal size="lg" isOpen={verModalGrupos} toggle={() => setVerModalGrupos(false)}>
                <ModalHeader>
                    <span>Importar alumnos</span>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='col-7'>
                            <Grupos grupoSeleccionado={grupoSeleccionado} seleccionar={true} />
                        </div>
                        <div className='col'>
                            <span>{grupoImp.Materia}</span>
                            <Table size="sm" hover striped>
                                <thead>
                                    <tr>
                                        <th style={{ width: 100 }}>Matricula</th>
                                        <th>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alumnosImp.map((o, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{o.Id}</td>
                                                <td>{o.Nombre}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <Button onClick={() => importarAlumnos(grupoImp.Id)} size="sm" color="secondary" outline><img src="/imagenes/ok.png" alt="importar" /> Importar</Button>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}
function Eje(props){
    return(
        <li>
            <h6 className='rojo'> {props.eje.Orden} - {props.eje.Texto}</h6>
            <ul>
                {props.eje.Temas.map((o,i)=><Tema key={i} eje={props.eje.Orden} tema={o} />)}
            </ul>
        </li> 
    )
}
function Tema(props){
    return(
        <li>
            <h6>{props.eje}.{props.tema.Orden} - {props.tema.Texto}</h6>
            {props.tema.SubTemas.length > 0 &&
                <ul>
                    {props.tema.SubTemas.map((o,i) =>
                        <li key={i}>
                            {props.eje}.{props.tema.Orden}.{i+1} - {o.Texto}
                        </li>)}
                </ul>
            }
        </li> 
    )
}