import React from 'react';
import { Card, CardHeader,CardBody } from 'reactstrap';
export function Panel({titulo,children}){
    return(
        <Card color="secondary" outline style={{ backgroundColor: 'transparent', padding: 1 }}>
            <CardHeader className="rojo"><b>{titulo}</b></CardHeader>
            <CardBody>{children}</CardBody>
        </Card>
    )
}

export function PanelSombra({titulo,children}){
    return(
        <div className='caja'>
            <h6>{titulo}</h6>
            <div>{children}</div>
        </div>
    )
}