import React from 'react';
import { Card, CardBody } from 'reactstrap';

export const Modulos = (props) => {
    return(
        <div className="container" style={{flex:1, verticalAlign:'center', alignContent:'center', alignItems:'center'}}>
            <br/><br/><br/>
            <div className="row">
                <div className='col-1'></div>
                <div className="col-2 cardGrupo" style={{textAlign :'center'}}>
                    <Card className='cardTema' style={{height:150}} onClick={()=> props.opcionSel('grupos','Grupos')}>
                        <CardBody>
                        <img src="/imagenes/clases.png" title="grupos" />
                        <br /><br />
                        <span className='rojo'>grupos</span>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2 cardGrupo" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}} onClick={() => props.opcionSel('tutorias', 'Tutorias')}>
                        <CardBody>
                            <img src="/imagenes/tutorias.png" title="tutorias" />
                            <br /><br />
                            <span className='rojo'>tutorias</span>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2 cardGrupo" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}} onClick={() => props.opcionSel('silabos', 'Diseño de syllabus')}>
                        <CardBody>
                            <img src="/imagenes/programas.png" title="diseño de syllabus" />
                            <br /><br />
                            <span className='rojo'>diseño de syllabus</span>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2" style={{ textAlign: 'center' }}>
                    <Card className='cardTema' style={{height:150}}>
                        <CardBody>
                            <img src="/imagenes/informes.png" title="informes" />
                            <br /><br />
                            <span className='rojo'>informes</span>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}